<template>
    <a style="font-family: 'Akshar'; 
                   text-decoration: none; 
                   text-decoration-color: #F97340; font-size: 25px; color: white" :href="`#${path.toLowerCase()}`">{{ path }}</a>
</template>

<script>
export default {
    name: 'HeaderPath',
    props: {
        path: {
            type: String,
            required: true
        }
    }
}
</script>