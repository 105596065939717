<template>
  <v-app>
    <v-app-bar
      app
      color="#171717"
      v-if="!$vuetify.breakpoint.mobile"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/brand.png"
          transition="fade-transition"
          width="150"
        />
      </div>
      
      <v-spacer></v-spacer>
      <header-path path="HOME" />
      <header-path path="SERVERS" class="ml-5" />
      <header-path path="WEBSTORE" class="ml-5" />
      <header-path path="DISCORD" class="ml-5" />
      <header-path path="TEAM" class="ml-5" />
      <v-spacer></v-spacer>

      <a @click="notify()"><img src="./assets/steam.png" style="width: 170px" alt=""></a>
    </v-app-bar>

    <v-app-bar
      app
      color="#171717"
      v-if="$vuetify.breakpoint.mobile"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/brand.png"
          transition="fade-transition"
          width="150"
        />
      </div>
      
      <v-spacer></v-spacer>

      <img src="./assets/steam.png" style="width: 170px" alt="">
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer padless color="#171717" v-if="!$vuetify.breakpoint.mobile" class="mt-16">
      <v-col
        class="text-center"
      >
        <v-img src="./assets/brand.png" max-width="150"></v-img>
      </v-col>
      <v-col
        class="text-center"
      >
        © {{ new Date().getFullYear() }} — <strong style="font-family: Akshar; color: #F77B38">NowaTV</strong> <strong>Gaming<br>Made by Fahrettin Enes with <v-icon class="mb-1" size="20" color="red">mdi-heart</v-icon></strong>
        <br>
        <strong style="font-family: Akshar; color: #767676">The website is not the final version, the development process continues. (WIP)</strong>
      </v-col>
      <v-col
        class="text-right"
      >
        <a style="font-family: Akshar; color: #767676; font-size: 20px;">Privacy Policy |
        </a>
        <a style="font-family: Akshar; color: #767676; font-size: 20px;">Cookies |
        </a>
        <a style="font-family: Akshar; color: #767676; font-size: 20px;">Contact
        </a>
      </v-col>
    </v-footer>

    <v-footer padless color="#171717" v-if="$vuetify.breakpoint.mobile" class="mt-16">
      <v-col
        class="text-center"
      >
        © {{ new Date().getFullYear() }} — <strong style="font-family: Akshar; color: #F77B38">NowaTV</strong> <strong>Gaming<br></strong>
        <br>
      </v-col>
      <v-col
        class="text-right"
      >
        <a style="font-family: Akshar; color: #767676; font-size: 20px;">Privacy Policy
        </a>
        <a style="font-family: Akshar; color: #767676; font-size: 20px;">Cookies |
        </a>
        <a style="font-family: Akshar; color: #767676; font-size: 20px;">Contact
        </a>
      </v-col>
    </v-footer>

    <notifications group="foo" />
  </v-app>
</template>

<style>
@font-face {
  font-family: "Akshar";
  src: local("Akshar"),
  url('./fonts/Akshar-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: "Akira";
  src: local("Akira"),
  url('./fonts/Akira Expanded Demo.otf') format("truetype");
}

@font-face {
  font-family: "Segoe";
  src: local("Segoe"),
  url('./fonts/Segoe UI.ttf') format("truetype");
}
</style>

<script>
import HeaderPath from './components/HeaderPath.vue'
export default {
  name: 'App',

  components: {
    HeaderPath
  },

  data: () => ({
    //
  }),

  methods: {
    notify() {
      this.$notify({
        group: 'foo',
        title: 'System Error',
        text: 'Our website is still under development, please try again later.',
        type: 'error',
      });
    }
  }
};
</script>