<template>
  <div class="home" id="home">
    <v-img
        alt="Vuetify Logo"
        class="shrink"
        src="../assets/banner.png"
        transition="fade-transition"
        height="700"
        v-if="!$vuetify.breakpoint.mobile"
      >
        <v-container style="max-width: 1280px;" class="mt-7">
          <v-row align="center" justify="center">
            <v-col md="5">
              <h2 style="font-family: Akira; font-size: 40px">WELCOME TO</h2>
              <h2 style="font-family: Akira; font-size: 40px"><span style="color: #F77B38;">NOWATV</span> GAMING</h2>
              <span style="font-size: 20px">NowaTV Gaming is a Game Servers and Gaming Community that hosts players in Turkey and now in Europe. 
                Our Mission is to provide the best gaming experience to all our players. Our vision is to host more games, 
                to open innovative and different servers.</span>
              <br>
              <v-row>
                <v-col cols="12" md="4">
                  <Button button="WEBSTORE" icon="mdi-cart-minus" color="F77B38" target="https://store.nowatvgaming.com" />
                </v-col>
                <v-col cols="12" md="4">
                  <Button button="DISCORD" icon="mdi-forum-outline" color="5865F2" target="https://discord.gg/8ZJGen5rPA" />
                </v-col>
              </v-row>
            </v-col>
            <v-col md="auto" v-if="!$vuetify.breakpoint.mobile">
              <v-img src="../assets/logo.png" width="300"></v-img>
            </v-col>
            <iframe width="560" height="280" class="mt-2 rounded" src="https://www.youtube.com/embed/paU9OIi3yAc" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen></iframe>
          </v-row>
        </v-container>
        <!-- <div class="countdown-home" style="opacity: 80%">
          <h2 class="mt-12 text-center" style="font-size: 40px">For a surprise</h2>
          <Countdown :date="new Date('2022-08-08T00:00:00.000Z')" />
        </div>, Yes :) -->
    </v-img>

    <v-img
        alt="Vuetify Logo"
        class="shrink"
        src="../assets/banner.png"
        transition="fade-transition"
        height="970"
        v-if="$vuetify.breakpoint.mobile"
      >
        <v-container style="max-width: 1280px;">
          <v-row align="center" justify="center">
            <v-col md="5">
              <h2 style="font-family: Akira; font-size: 40px">WELCOME TO</h2>
              <h2 style="font-family: Akira; font-size: 40px"><span style="color: #F77B38;">NOWATV</span> GAMING</h2>
              <span style="font-size: 20px">NowaTV Gaming is a Game Servers and Gaming Community that hosts players in Turkey and now in Europe. 
                Our Mission is to provide the best gaming experience to all our players. Our vision is to host more games, 
                to open innovative and different servers.</span>
              <br>
              <v-row>
                <v-col cols="12" md="4">
                  <Button button="WEBSTORE" icon="mdi-cart-minus" color="F77B38" target="https://store.nowatvgaming.com" />
                </v-col>
                <v-col cols="12" md="4">
                  <Button button="DISCORD" icon="mdi-forum-outline" color="5865F2" target="https://discord.gg/8ZJGen5rPA" />
                </v-col>
              </v-row>
            </v-col>
            <v-col md="auto" v-if="!$vuetify.breakpoint.mobile">
              <v-img src="../assets/logo.png" width="300"></v-img>
            </v-col>
            <iframe width="350" height="280" class="mt-7 rounded" src="https://www.youtube.com/embed/paU9OIi3yAc" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen></iframe>
          </v-row>
        </v-container>
        <!-- <div class="countdown-home" style="opacity: 80%">
          <h2 class="mt-12 text-center" style="font-size: 40px">For a surprise</h2>
          <Countdown :date="new Date('2022-08-08T00:00:00.000Z')" />
        </div>, Yes :) -->
    </v-img>

    <h2 class="text-center mt-16" style="font-size: 40px; text-decoration: underline;
     text-decoration-color: #F97340;" id="servers">SERVERS</h2>

    <v-container>
      <v-row justify="center">
        <v-col md="4" class="text-center">
          <v-img src="https://unturned-servers.net/server/293963/banners/banner-1.png"></v-img>
          <v-row>
            <v-col>
              <v-btn color="green" class="mt-3" href="steam://connect/141.95.10.210:27015" target="_blank">Play</v-btn>
            </v-col>
            <v-col>
              <v-btn color="orange" class="mt-3" href="https://unturned-servers.net/server/293963/vote/" target="_blank">Vote</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <h2 class="text-center mt-16" style="font-size: 40px; text-decoration: underline;
     text-decoration-color: #F97340;" id="webstore">WEBSTORE</h2>

    <v-container style="max-width: 1400px;">
      <v-row justify="center" align="center">
        <v-col md="4">
          <span style="font-size: 25px; font-family: Akira;">If you feel that we have done a great job and you enjoy playing on our servers,
             you can support us by purchasing products in our store :)</span>
        </v-col>
        <v-col md="auto">
          <a href="https://store.nowatvgaming.com" target="_blank"><v-img src="../assets/webstore.png"></v-img></a>
        </v-col>
      </v-row>
    </v-container>

    <h2 class="text-center mt-16" style="font-size: 40px; text-decoration: underline;
        text-decoration-color: #F97340;" id="discord">DISCORD</h2>

    <v-container style="max-width: 1280px">
      <v-row justify="center" align="center">
        <v-col md="3">
          <a href="https://discord.gg/7asN5sMnm3" target="_blank"><v-img src="../assets/iphone.png" max-width="200"></v-img></a>
        </v-col>
        <v-col md="4">
          <span style="font-size: 25px; font-family: Akira;">Announcements, Events, News, Guides, Giveaways &
             More will be posted on our Discord Server!</span>
        </v-col>
      </v-row>
    </v-container>

    <h2 class="text-center mt-16" style="font-size: 40px; text-decoration: underline;
        text-decoration-color: #F97340;" id="team">OUR TEAM</h2>

    <v-container style="max-width: 1280px">
      <v-row justify="center">
        <v-col md="4" v-for="member in team">
          <v-card>
            <v-container class="text-center">
              <v-img width="90"
                 class="mx-auto"
                 :src="member.image"
                 style="border-radius: 50px"></v-img>
              <h2 class="mt-2">{{member.name}}</h2>
              <h3 :style="'color: ' + member.titleColor">{{member.title}}</h3>
              <h5 :style="'color: #4759E6'" v-for="task in member.tasks">{{task.name}}</h5>
              <v-row style="margin-top: 0px;">
                <v-col v-for="link in member.media">
                  <v-btn outlined rounded small :href="link.link" target="_blank">
                    <v-icon :color="link.color" size="15" style="margin-top: 2px;" class="mr-1">{{link.icon}}</v-icon> 
                    {{link.name}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<style>
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px black;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

h2 {
  font-family: 'Akshar';
}

html {
  scroll-behavior: smooth;
}
</style>

<script>
import Server from '@/components/Server.vue';
import Button from '../components/Button.vue'
import Countdown from '@/components/Countdown.vue';
export default {
  name: 'Home',

  components: {
    Button,
    Server,
    Countdown
  },

  data() {
    return {
      team: [
        {
          name: 'MaSSive',
          image: 'https://cdn.discordapp.com/avatars/276820586892623873/f870cf70ff530aaae85e46842a663bed.webp?size=128',
          title: 'OWNER',
          titleColor: '#ff0004',
          media: [
            {
              name: 'Youtube',
              icon: 'mdi-youtube',
              color: 'red',
              link: 'https://www.youtube.com/c/NoWaTV'
            },
            {
              name: 'Steam',
              icon: 'mdi-steam',
              color: '#c7d5e0',
              link: 'https://steamcommunity.com/id/NoWaTV'
            },
            {
              name: 'Discord',
              icon: 'mdi-ghost',
              color: 'blue',
              link: 'https://discordapp.com/users/276820586892623873'
            },
          ],
          tasks: [
            { name: 'Project Manager' },
            { name: 'Map Designer' },
          ]
        },
        {
          name: 'Leqendd',
          image: 'https://cdn.discordapp.com/attachments/996847783569018890/1008882284843237397/2022_DC.png',
          title: 'OWNER',
          titleColor: '#ff0004',
          media: [
            {
              name: 'Steam',
              icon: 'mdi-steam',
              color: '#c7d5e0',
              link: 'https://steamcommunity.com/id/Leqendd'
            },
            {
              name: 'Discord',
              icon: 'mdi-ghost',
              color: 'blue',
              link: 'https://discordapp.com/users/307783350037250049'
            },
          ],
          tasks: [
            { name: 'Project Manager' },
            { name: 'Developer' },
          ]
        },
        {
          name: 'Fahrettin Enes',
          image: 'https://cdn.discordapp.com/avatars/875060734969655307/33bbadb8e5d674b5128b5fb46566522b.webp?size=128',
          title: 'OWNER',
          titleColor: '#ff0004',
          media: [
            {
              name: 'GitHub',
              icon: 'mdi-github',
              color: 'white',
              link: 'https://github.com/fahrettinenes'
            },
            {
              name: 'Discord',
              icon: 'mdi-ghost',
              color: 'blue',
              link: 'https://discordapp.com/users/875060734969655307'
            },
          ],
          tasks: [
            { name: 'Project Manager' },
            { name: 'Developer' },
          ]
        },
            {
              name: 'SwinD',
              image: 'https://cdn.discordapp.com/avatars/355388968394686497/a_aa04bd6b0dbb26593c03b34960620209.gif?size=128',
              title: 'OWNER',
              titleColor: '#ff0004',
              media: [
                {
                  name: 'Steam',
                  icon: 'mdi-steam',
                  color: '#c7d5e0',
                  link: 'https://steamcommunity.com/id/Swindd'
                },
                {
                  name: 'Discord',
                  icon: 'mdi-ghost',
                  color: 'blue',
                  link: 'https://discordapp.com/users/355388968394686497'
                },
              ],
              tasks: [
                { name: '3D Artist' },
                { name: 'Mods & Workshop Manager' },
              ]
            },
        {
          name: 'Rokunoru',
          image: 'https://cdn.discordapp.com/avatars/590536150935142419/b6f153bdab6b4a22743cfbb5d3bf9c18.webp?size=128',
          title: 'CO-OWNER',
          titleColor: '#ffd03a',
          media: [
            {
              name: 'Behance',
              icon: 'mdi-image-album',
              color: '#0063ff',
              link: 'https://www.behance.net/mertrokunoru'
            },
            {
              name: 'Steam',
              icon: 'mdi-steam',
              color: '#c7d5e0',
              link: 'https://steamcommunity.com/profiles/76561198153951136'
            },
            {
              name: 'Discord',
              icon: 'mdi-ghost',
              color: 'blue',
              link: 'https://discordapp.com/users/590536150935142419'
            },
          ],
          tasks: [
            { name: 'Creative / Art Manager' },
            { name: 'Head Designer' },
          ]
        },
        {
          name: 'Gökberk',
          image: 'https://cdn.discordapp.com/avatars/576693957367693313/fca9ad37b9ca80c2f32aa71262b27bb8.webp?size=128',
          title: 'CO-OWNER',
          titleColor: '#ffd03a',
          media: [
            {
              name: 'Steam',
              icon: 'mdi-steam',
              color: '#c7d5e0',
              link: 'https://steamcommunity.com/profiles/76561198249655742'
            },
            {
              name: 'Discord',
              icon: 'mdi-ghost',
              color: 'blue',
              link: 'https://discordapp.com/users/576693957367693313'
            },
          ],
          tasks: [
            { name: 'Game Staff' },
            { name: 'Discord Staff' },
          ]
        },
        {
          name: 'Arda',
          image: 'https://avatars.cloudflare.steamstatic.com/0e77bbc61a64a0c3b5d723aae39d48694e0ef1e1_full.jpg',
          title: 'ADMIN',
          titleColor: 'cyan',
          media: [
            {
              name: 'Steam',
              icon: 'mdi-steam',
              color: '#c7d5e0',
              link: 'https://steamcommunity.com/profiles/76561199046287236/'
            },
            {
              name: 'Discord',
              icon: 'mdi-ghost',
              color: 'blue',
              link: 'https://discordapp.com/users/931937396776370257'
            },
          ],
          tasks: [
            { name: 'Game Staff' },
            { name: 'Discord Staff' },
          ]
        },
        {
          name: 'Blackland',
          image: 'https://avatars.cloudflare.steamstatic.com/b8ac101411c542983412d3c3dc5b0129dc019355_full.jpg',
          title: 'ADMIN',
          titleColor: 'cyan',
          media: [
            {
              name: 'Steam',
              icon: 'mdi-steam',
              color: '#c7d5e0',
              link: 'https://steamcommunity.com/profiles/76561198313035940/'
            },
            {
              name: 'Discord',
              icon: 'mdi-ghost',
              color: 'blue',
              link: 'https://discordapp.com/users/618542320702455810'
            },
          ],
          tasks: [
            { name: 'Game Staff' },
            { name: 'Discord Staff' },
          ]
        },
        {
          name: 'Morpax',
          image: 'https://avatars.cloudflare.steamstatic.com/79a8119bd2a027755f93872d0d09b959909a0405_full.jpg',
          title: 'ADMIN',
          titleColor: 'cyan',
          media: [
            {
              name: 'Steam',
              icon: 'mdi-steam',
              color: '#c7d5e0',
              link: 'https://steamcommunity.com/id/morpax/'
            },
            {
              name: 'Discord',
              icon: 'mdi-ghost',
              color: 'blue',
              link: 'https://discordapp.com/users/355767819209670657'
            },
          ],
          tasks: [
            { name: 'Game Staff' },
            { name: 'Discord Staff' },
          ]
        },
        {
          name: 'BraggerTR',
          image: 'https://avatars.cloudflare.steamstatic.com/f39c6ae7fa389cba9392084628ce35f0eef86587_full.jpg',
          title: 'MODERATOR',
          titleColor: 'purple',
          media: [
            {
              name: 'Steam',
              icon: 'mdi-steam',
              color: '#c7d5e0',
              link: 'https://steamcommunity.com/id/braggertr/'
            },
            {
              name: 'Discord',
              icon: 'mdi-ghost',
              color: 'blue',
              link: 'https://discordapp.com/users/825826666973757550'
            },
          ],
          tasks: [
            { name: 'Game Staff' },
            { name: 'Discord Staff' },
          ]
        },
        {
          name: 'Emir',
          image: 'https://avatars.cloudflare.steamstatic.com/2b18c24199303a4240a7b78d222cd0b7df19066d_full.jpg',
          title: 'MODERATOR',
          titleColor: 'purple',
          media: [
            {
              name: 'Steam',
              icon: 'mdi-steam',
              color: '#c7d5e0',
              link: 'https://steamcommunity.com/id/50cSkender/'
            },
            {
              name: 'Discord',
              icon: 'mdi-ghost',
              color: 'blue',
              link: 'https://discordapp.com/users/591253475862642691'
            },
          ],
          tasks: [
            { name: 'Game Staff' },
            { name: 'Discord Staff' },
          ]
        },
        {
          name: 'EndlesSCaptain',
          image: 'https://avatars.akamai.steamstatic.com/a7ca150e6c0ef98719aa0b181ba60d3c6be3b636_full.jpg',
          title: 'MODERATOR',
          titleColor: 'purple',
          media: [
            {
              name: 'Steam',
              icon: 'mdi-steam',
              color: '#c7d5e0',
              link: 'https://steamcommunity.com/id/EndlesSCaptainn/'
            },
            {
              name: 'Discord',
              icon: 'mdi-ghost',
              color: 'blue',
              link: 'https://discordapp.com/users/403973271143579658'
            },
          ],
          tasks: [
            { name: 'Game Staff' },
            { name: 'Discord Staff' },
          ]
        },
      ]
    }
  }
};
</script>
