<template>
    <button class="mt-3" :href="target" :style="`background-color: #${color};
        border-radius: 25px; min-width: 160px; min-height: 58px;`">
        <a style="color: white; text-decoration: none" :href="target" target="_blank">
            <h3 style="font-family: 'Akira'; font-size: 16px">{{ button }}</h3>
            <v-icon>{{ icon }}</v-icon>
        </a>
    </button>
</template>

<script>
export default {
    props: {
        button: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        color: {
            type: String,
            required: true
        },
        target: {
            type: String,
            required: true
        }
    }
}
</script>