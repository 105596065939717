<template>
    <v-img src="../assets/server.png" max-width="600">
        <v-container class="text-center">
            <h2>SERVER NAME</h2>
            <h3 style="color: gray">Server Description</h3>

            <v-card class="pa-2 text-center">
                <v-row align="center" no-gutters>
                    <v-col>
                        <v-img src="../assets/logo.png" width="40"></v-img>
                    </v-col>
                    <v-col>
                        <span>play.nowatvgaming.com:27015</span>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn max-width="70" max-height="30" color="green">5/24</v-btn>
                    </v-col>
                </v-row>
            </v-card>
            
            <v-btn class="mt-3" block color="#F77B38" style="border-radius: 1px;">Click to join!</v-btn>
        </v-container>
    </v-img>
</template>

<script>
export default {
    name: 'Server',
}
</script>